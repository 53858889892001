.dots {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.dots__item {
    width: 10px;
    height: 10px;
    background-color: rgba(127, 140, 153, 0.35);
    border-radius: 50%;
    transition: background-color 225ms;
    cursor: pointer;
}

.dots__item--active {
    background-color: var(--color-mts-company);
}
